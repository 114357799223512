import { Directive, inject, input } from '@angular/core';

import { FormFieldComponent } from '../form-field.component';

@Directive({
  standalone: true,
  selector: 'input[diInput], select[diInput], textarea[diInput]',
  host: {
    class: 'form-control',
    '[attr.id]': 'id() ?? formField.id',
    '[class.has-error]': 'formField.errorsVisible()',
    '[attr.aria-invalid]': 'formField.errorsVisible()',
    '[attr.aria-errormessage]': 'formField.errorMessageId()',
  },
})
export class FormFieldInputDirective {
  protected readonly formField = inject(FormFieldComponent);

  protected readonly id = input<string>();
}
