import { Injectable, inject, signal } from '@angular/core';
import { WsActionV3Completed } from '@bo-schema-ws/server-payloads/ws-action-v3-completed';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, tap } from 'rxjs/operators';

import {
  BadRequestDto,
  WebSocketClient,
  WsServerPayloadEnum,
} from '@data-import/data-access/bulk-operations-api';

import { ToasterService } from '#shared/components/toaster/toaster.service';

import { PanelState } from '../common/panel-state.model';
import { SelectedEmployeeService } from './selected-employee.service';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class EmployeeActionsToastService {
  private readonly webSocketClient = inject(WebSocketClient);
  private readonly toasterService = inject(ToasterService);
  private readonly selectedEmployeeService = inject(SelectedEmployeeService);
  public readonly panelState = signal<PanelState>('viewing');

  constructor() {
    this.subscribeToWebSocket();
  }

  private subscribeToWebSocket() {
    this.webSocketClient
      .getMessagesByType(WsServerPayloadEnum.EmployeeCardEditActionCompleted)
      .pipe(
        untilDestroyed(this),
        tap((data) => this.handleWebSocketEvent(data)),
      )
      .subscribe();
  }

  private handleWebSocketEvent(data) {
    if (!data.errorMessage) {
      this.showSuccessToast(data.actionV3Id);
      this.panelState.set('viewing');
    } else {
      this.showErrorToast(data.employeeId, data.errorMessage);
      this.panelState.set('editing');
    }
  }

  private showSuccessToast(actionV3Id: string) {
    const employeeNumber = this.selectedEmployeeService.employeeNumber();
    const firstName = this.selectedEmployeeService.employee().firstName;
    const lastName = this.selectedEmployeeService.employee().lastName;
    const toastMessage = $localize`data were saved!`;

    this.toasterService.showSuccess(
      `${employeeNumber} - ${firstName} ${lastName} ${toastMessage}`,
      actionV3Id,
    );
  }

  private showErrorToast(employeeId: string, errorMessage: string) {
    const errorMsg = $localize`Employee data were not saved!`;
    const errorId = `${employeeId}:${errorMessage}`;
    this.toasterService.showError(`${errorMsg} ${errorMessage}`, errorId);
  }

  public handleSaveError(error: BadRequestDto): void {
    const errorMsg = $localize`Error while saving changes!`;
    const errorDetails = error?.validationItems
      ? error.validationItems.map((item) => `${item.propertyName}: "${item.message}"`).join('; ')
      : error?.generalError || 'Unknown error';

    this.toasterService.showError(
      `${errorMsg} ${errorDetails}`,
      error?.generalError || 'saveError',
    );
  }

  public handleCreateSuccess(personalDetails: PersonalDetails) {
    this.toasterService.showSuccess(
      $localize`New position for employee ${personalDetails.employeeNumber} - ${personalDetails.firstName} ${personalDetails.lastName} was created successfully`,
    );
  }

  public handleHiringSuccess(personalDetails: PersonalDetails) {
    this.toasterService.showSuccess(
      $localize`${personalDetails.employeeNumber} - ${personalDetails.firstName} ${personalDetails.lastName} was hired successfully`,
    );
  }

  public handleHiringError(error) {
    this.toasterService.showError(
      $localize`Unhandled error when creating employee. ${error}`,
      'unhandled-error-creating-employee',
    );
  }
}

type PersonalDetails = {
  employeeNumber: string;
  firstName: string;
  lastName: string;
};
