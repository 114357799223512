import { Directive, DoCheck, OnInit, input, signal } from '@angular/core';
import { FormControl, NgControl, NgModel, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@Directive({
  standalone: true,
  selector: 'label[diRequiredAsterisk]',
  host: {
    '[class.required-label]': 'isRequired()',
    '[class.required-top]': 'isRequired()',
  },
})
@UntilDestroy()
export class RequiredAsteriskDirective implements OnInit, DoCheck {
  readonly value = input.required<NgControl | NgModel | FormControl | boolean>({
    alias: 'diRequiredAsterisk',
  });
  protected isRequired = signal<boolean>(false);

  // Not a great solution, but attachToStatusChanges() is not reliable
  // this.value() does not always return latest NgControl
  // therefore statusChanges subscribing to outdated reference
  ngDoCheck(): void {
    this.updateIsRequired();
  }

  ngOnInit() {
    this.updateIsRequired();
    //this.attachToStatusChanges();
  }

  private updateIsRequired(): void {
    const value = this.value();
    let isRequired = false;

    if (typeof value === 'boolean') {
      isRequired = value;
    }
    if (value instanceof NgControl) {
      isRequired = value.control.hasValidator(Validators.required);
    }
    if (value instanceof NgModel) {
      isRequired = value.control.hasValidator(Validators.required);
    }
    if (value instanceof FormControl) {
      isRequired = value.hasValidator(Validators.required);
    }

    this.isRequired.set(isRequired);
  }

  private attachToStatusChanges(): void {
    const value = this.value();
    let statusChanges$ = null;

    if (value instanceof NgControl) {
      statusChanges$ = value.statusChanges;
    }
    if (value instanceof NgModel) {
      statusChanges$ = value.statusChanges;
    }
    if (value instanceof FormControl) {
      statusChanges$ = value.statusChanges;
    }

    if (statusChanges$) {
      statusChanges$.pipe().subscribe(() => {
        this.updateIsRequired();
      });
    }
  }
}
