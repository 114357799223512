import { Directive, TemplateRef, effect, inject, input } from '@angular/core';

import { FormFieldErrorsService } from './form-errors.service';

@Directive({
  standalone: true,
  selector: 'ng-template[diError]',
})
export class FormFieldErrorDirective {
  public readonly templateRef = inject(TemplateRef, { self: true });
  public readonly formErrorsService = inject(FormFieldErrorsService);

  public readonly errorKey = input.required<string>({ alias: 'diError' });

  constructor() {
    effect(
      () => {
        this.formErrorsService.registerErrorMessage(this.errorKey(), this.templateRef);
      },
      { allowSignalWrites: true },
    );
  }
}
