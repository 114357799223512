import { Component, inject } from '@angular/core';

import { FormFieldComponent } from '#shared/features/form-field';

@Component({
  selector: 'di-form-field-desc',
  standalone: true,
  templateUrl: './form-field-desc.component.html',
})
export class FormFieldDescComponent {
  protected readonly formField = inject(FormFieldComponent, { host: true });
}
