import { Injectable, TemplateRef, computed, forwardRef, inject, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FormFieldErrorsService {
  private readonly parentService = inject<FormFieldErrorsService>(
    forwardRef(() => FormFieldErrorsService),
    { skipSelf: true, optional: true },
  );

  private readonly _errorMessages = signal(new Map<string, TemplateRef<unknown>>());

  public readonly errorMessages = computed<{ [key: string]: TemplateRef<unknown> }>(() => {
    const parentMessages = this.parentService?.errorMessages() ?? {};
    return {
      ...parentMessages,
      ...Object.fromEntries(this._errorMessages()),
    };
  });

  public registerErrorMessage(errKey: string, message: TemplateRef<unknown>) {
    this._errorMessages.update((map) => map.set(errKey, message));
  }
}
