<div class="tw-flex tw-flex-row text-error tw-gap-0.5" aria-live="assertive" [attr.id]="id">
  <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-filled vismaicon-error right-auto tw-m-0"></span>
  <!-- TODO: temporal workaround to bypass false-positive NG0956 warning: https://github.com/angular/angular/issues/56471 -->
  <div class="tw-leading-tight tw-text-xs">
    @for (errKey of errorKeys(); track errorTrackFn(errKey); let last = $last) {
      <div>
        @if (formErrorsService.errorMessages()[errKey]) {
          <ng-template
            [ngTemplateOutlet]="formErrorsService.errorMessages()[errKey]"
            [ngTemplateOutletContext]="{ $implicit: errors()[errKey], error: errors()[errKey] }"
          />
        } @else {
          <!-- Untranslated error message -->
          Error: <strong>{{ errKey }}</strong
          >.
        }
      </div>
    }
  </div>
</div>
