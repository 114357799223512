import { NgModule } from '@angular/core';

import { FormFieldDescComponent } from '#shared/features/form-field-desc/form-field-desc.component';

import { FormFieldErrorDirective } from './form-errors';
import { FormFieldComponent } from './form-field.component';
import { FormFieldInputDirective } from './input/input.directive';
import { FormFieldLabelDirective } from './label/label.directive';

@NgModule({
  imports: [
    FormFieldComponent,
    FormFieldInputDirective,
    FormFieldErrorDirective,
    FormFieldLabelDirective,
    FormFieldDescComponent,
  ],
  exports: [
    FormFieldComponent,
    FormFieldInputDirective,
    FormFieldErrorDirective,
    FormFieldLabelDirective,
    FormFieldDescComponent,
  ],
})
export class FormFieldModule {}
