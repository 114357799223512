<label
  class="tw-mb-0.5 tw-leading-none"
  [class.text-error]="errorsVisible() || groupErrorsVisible()"
  [diRequiredAsterisk]="required() ?? ngControl()"
  [for]="id"
>
  @if (labelTemplate()) {
    <ng-template [ngTemplateOutlet]="labelTemplate().templateRef" />
  } @else {
    <!-- using ng-container to avoid empty spaces -->
    <ng-container>{{ label() }}</ng-container>
  }
</label>
<ng-content />
@if (errorsVisible()) {
  <di-errors [errors]="ngControl().errors" />
}
