import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  EmployeeClient,
  EmployeeV3ForCardDto,
  EmployeeV3ForCardResponseDto,
  EmployeeV3PositionItemDto,
} from '@bo-schema-api-dto';
import { BehaviorSubject, Observable, filter, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';

import { WebSocketClient, WsServerPayloadEnum } from '@data-import/data-access/bulk-operations-api';

import { getActiveTimelineItem } from '#shared/helpers/timeline.helpers';

import { EnumEmployeeCardState } from './employee-card-state.enum';

@Injectable({
  providedIn: 'root',
})
export class SelectedEmployeeService {
  private employeeIdSignal: WritableSignal<string> = signal(undefined);
  private legacyEmployeeIdSignal: WritableSignal<string> = signal(undefined);
  private positionIdSignal: WritableSignal<string> = signal(undefined);
  private employeeNumberSignal: WritableSignal<string> = signal(undefined);
  private positionIndexSignal: WritableSignal<number> = signal(undefined);

  private employeeSignal: WritableSignal<EmployeeV3ForCardDto> = signal(undefined);
  private employee$ = toObservable(this.employeeSignal);
  private positionSignal: WritableSignal<EmployeeV3PositionItemDto> = signal(undefined);
  private position$ = toObservable(this.positionSignal);
  private stateSubject = new BehaviorSubject<EnumEmployeeCardState>(EnumEmployeeCardState.Loading);
  private state$ = this.stateSubject.asObservable();

  constructor(
    private employeeClient: EmployeeClient,
    private webSocketClient: WebSocketClient,
  ) {
    this.handleEmployeeChange();
    this.subscribeToWebSocket();
  }

  public get employeeId(): Signal<string> {
    return this.employeeIdSignal.asReadonly();
  }

  public get positionId(): Signal<string> {
    return this.positionIdSignal.asReadonly();
  }

  public get legacyEmployeeId(): Signal<string> {
    return this.legacyEmployeeIdSignal.asReadonly();
  }

  public get employeeNumber(): Signal<string> {
    return this.employeeNumberSignal.asReadonly();
  }

  public get employee(): Signal<EmployeeV3ForCardDto> {
    return this.employeeSignal.asReadonly();
  }

  public get position(): Signal<EmployeeV3PositionItemDto> {
    return this.positionSignal.asReadonly();
  }

  public get positionIndex(): Signal<number> {
    return this.positionIndexSignal.asReadonly();
  }

  public getEmployee(): Observable<EmployeeV3ForCardDto> {
    return this.employee$.pipe(filter((employee) => !!employee));
  }

  public getPosition(): Observable<EmployeeV3PositionItemDto> {
    return this.position$.pipe(filter((position) => !!position));
  }

  public getState(): Observable<EnumEmployeeCardState> {
    return this.state$;
  }

  public setActivePosition(positionId: string): void {
    this.stateSubject.next(EnumEmployeeCardState.Loading);
    const position = this.employee().positionTimeline.find((item) => item.value.id === positionId);

    this.positionIdSignal.set(positionId);
    this.positionSignal.set(position);
    this.stateSubject.next(EnumEmployeeCardState.Ready);
  }

  public setEmployee(legacyEmployeeId: string): void {
    this.stateSubject.next(EnumEmployeeCardState.Loading);
    this.legacyEmployeeIdSignal.set(legacyEmployeeId);
  }

  public cleanupEmployee(): void {
    this.employeeIdSignal.set(undefined);
    this.legacyEmployeeIdSignal.set(undefined);
    this.positionIdSignal.set(undefined);
    this.employeeNumberSignal.set(undefined);
    this.positionIndexSignal.set(undefined);
    this.employeeSignal.set(undefined);
    this.positionSignal.set(undefined);
  }

  private handleEmployeeChange(): void {
    const legacyEmployeeId$ = toObservable(this.legacyEmployeeIdSignal).pipe(filter((id) => !!id));

    legacyEmployeeId$
      .pipe(
        switchMap((legacyEmployeeId) => this.employeeClient.getLegacyEmployeeV3(legacyEmployeeId)),
      )
      .subscribe({
        next: this.onNextGetLegacyEmployeeV3.bind(this),
        error: this.onErrorGetLegacyEmployeeV3.bind(this),
      });
  }

  private subscribeToWebSocket() {
    this.webSocketClient
      .getFilteredStream(
        WsServerPayloadEnum.EmployeeCardEditActionCompleted,
        WsServerPayloadEnum.PositionCreateActionCompleted,
      )
      .subscribe(() => {
        if (!this.legacyEmployeeIdSignal()) {
          return;
        }

        this.employeeClient
          .getLegacyEmployeeV3(this.legacyEmployeeIdSignal())
          .pipe(take(1))
          .subscribe({
            next: this.onNextGetLegacyEmployeeV3.bind(this),
            error: this.onErrorGetLegacyEmployeeV3.bind(this),
          });
      });
  }

  private onNextGetLegacyEmployeeV3(result: EmployeeV3ForCardResponseDto) {
    this.employeeSignal.set(result.employee);
    this.employeeIdSignal.set(result.employee.id);
    this.employeeNumberSignal.set(result.employee.customId);

    // TODO: add handling when no position is currently active
    let positionIndex = result.employee.positionTimeline.findIndex(
      (item) => item === getActiveTimelineItem(result.employee.positionTimeline),
    );

    if (positionIndex === -1) {
      positionIndex = result.employee.positionTimeline.length - 1;
    }

    const position = result.employee.positionTimeline[positionIndex];

    this.positionSignal.set(position);
    this.positionIdSignal.set(position.value.id);
    this.positionIndexSignal.set(positionIndex + 1);

    this.stateSubject.next(EnumEmployeeCardState.Ready);
  }

  private onErrorGetLegacyEmployeeV3(err: any) {
    this.stateSubject.next(EnumEmployeeCardState.Error);
    throw err;
  }
}
